#main-nav {
    z-index: 99;
}

.navbar-expand-lg .navbar-nav .nav-link {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin-left: 35px;
    padding-left: 5px;
    padding-right: 5px;
    color: $white;
    letter-spacing: .5px;
    position: relative;
    @include desktop-lg {
        margin-left: 25px;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 6px;
        left: 0;
        width: 100%;
        height: 35%;
        background: $primary-color;
        transform: scale3d(1, 0, 1);
        transform-origin: 50% 100%;
        transition: transform 0.3s;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    }

    &:hover:before,
    &:focus :before {
        transform: scale3d(1, 1, 1);
        transform-origin: 50% 0%;
    }
}

.nav-top {
    background: rgba(0, 0, 0, 0.9);
}


ul.dropdown-menu {
    background: $white;
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    border-top: 2px solid $primary-color;
}

ul.dropdown-menu>li>a,
.navbar ul ul ul.dropdown-menu li .nav-link {
    font-size: 12px;
    line-height: 22px;
    display: block;
    position: relative;
    z-index: 1;
    padding-left: 15px;
    transition: all .3s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $black;
    text-transform: uppercase;

    &:hover {
        background: $primary-color;
        color: $white;

    }
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: $white;
    text-decoration: none;
    background-color: transparent;

}

.dropdown-menu {
    border-radius: 0;
    padding: 0;
    border: 0;
    display: block;
    opacity: 0;
    visibility: hidden;
    min-width: 210px;
    transform: scaleY(0);
    transform-origin: center top 0;
    transition: all .3s ease-in-out 0s;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
}


@include mobile {
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .btn-group,
    .btn-group-vertical {
        display: block;
    }

    .btn-group>.btn {
        padding: 20px;
    }
}



@include mobile-xs {
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .btn-group,
    .btn-group-vertical {
        display: block;
    }

    .btn-group>.btn {
        padding: 20px;
    }
}

@include tablet {
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .btn-group,
    .btn-group-vertical {
        display: block;
    }

    .btn-group>.btn {
        padding: 20px;
    }
}

@include desktop {
    .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .btn-group,
    .btn-group-vertical {
        display: block;
    }

    .btn-group>.btn {
        padding: 20px;
    }
}

.dropdown-toggle::after {
    content: " \f107";
    font-family: "Font Awesome 5 free";
    border: 0px;
    font-size: 14px;
    vertical-align: top;
    margin-left: 10px;
    font-weight: 600;
}


.navbar-expand-lg.main-nav .navbar-brand {
    color: $white;
}

.navbar-toggler {
    background: $white;
    border-radius: 0px;
}


@include mobile {
    #main-nav {
        background: $black;
    }
}

@include mobile-xs {
    #main-nav {
        background: $black;
    }
}

@include tablet {
    #main-nav {
        background: $black;
    }
}

@include desktop {
    #main-nav {
        background: $black;
    }
}