

.video-item{
	position: relative;

	&:before{
		position: absolute;
		content:"";
		left: 0px;
		top:0px;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.6);
	}

	.videoplay{
		z-index: 2;
		position: absolute;
		left: 50%;
		top: 50%;
		font-size: 30px;
		cursor: pointer;
		margin-left: -25px;
		margin-top: -34px;

		i{
			color: $white;
			width: 60px;
			height: 60px;
			border-radius: 100%;
			border: 1px solid $white;
			text-align: center;
			padding-top: 15px;
			display: inline-block;
			-webkit-transition: all .35s ease;
			   -moz-transition: all .35s ease;
			    -ms-transition: all .35s ease;
			     -o-transition: all .35s ease;
			        transition: all .35s ease;
		}

		&:hover i{
			background: $primary-color;
			border-color: $primary-color;
		}
	}
}

@include mobile{
	.btn-group, .btn-group-vertical {
	    display: block;
	}
}
@include mobile-xs{
	.btn-group, .btn-group-vertical {
	    display: block;
	}
}