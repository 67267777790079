.section-header {
	background: url("../images/bg/bg-2.jpg") 50% 50% no-repeat;
	background-size: cover;
	padding: calc(70px + 9rem) 0 9rem;
	position: relative;


	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: "";
		opacity: .5;
		background-color: #000;
	}
}


.booking {
	background: $primary-color;
	color: $white;
	padding: 10px 90px 10px 30px;
	margin-left: -116px;
	display: inline-block;
	@include desktop {
		margin-left: 0;
	}
}

// SERVICES
.service-item {
	text-align: center;

	h3 {
		margin-bottom: 15px;
		font-size: 24px;
		font-weight: 400;
		margin-top: 20px;
	}

	i {
		font-size: 50px;
		-webkit-transition: all .35s ease;
		-moz-transition: all .35s ease;
		-ms-transition: all .35s ease;
		-o-transition: all .35s ease;
		transition: all .35s ease;
	}

	&:hover i {
		color: $primary-color;
	}
}

.letter-spacing-2 {
	letter-spacing: 3px;
	text-transform: uppercase;
}


//  CHEF SECTION
.chef-item {
	ul {
		margin-top: 30px;

		li i {
			width: 30px;
			height: 30px;
			background: #eee;
			display: inline-block;
			text-align: center;
			padding-top: 7px;
			color: rgba(0, 0, 0, 0.5);
			-webkit-transition: all .35s ease;
			-moz-transition: all .35s ease;
			-ms-transition: all .35s ease;
			-o-transition: all .35s ease;
			transition: all .35s ease;

			&:hover {
				background: $primary-color;
				color: $white;
			}
		}
	}
}

/// Testimonial
/// 

.testimonial {
	p {
		font-size: 22px;
		line-height: 38px;
		margin-bottom: 45px;
	}

	.author {
		img {
			width: 80px;
			height: 80px;
			border-radius: 100%;
			margin: 0 auto;
		}

		p {
			font-size: 14px;
			margin-bottom: 0px;
			line-height: 18px;
		}
	}
}

// INstagram
.insta-title {
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	z-index: 2;
	background: $primary-color;
	padding: 4px 20px;
	color: $white;
	transform: translate(-50%, -50%);
}