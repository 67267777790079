.blog-meta {
	margin-top: 20px;
	font-size: 18px;
}


.post-cat{
	background: #eee;
	display: inline-block;
	padding: 3px 10px;
	text-transform: capitalize;
}

.post-thumb{
	margin-bottom: 30px;
}


// POST SIDEBASR
// 


.widget.category{
	ul{
		line-height: 40px;
	}
}

.widget.tags{
	ul li a{
		font-size: 16px;
		background: #f8f6f2;
		color: #827f79;
		padding: 4px 17px;
		margin-bottom: 10px;
		margin-right: 4px;
		display: inline-block;
		text-transform: capitalize;

		&:hover{
			background: $black;
			color: $white;
		}
	}
}


.search-form-widget{
	position: relative;

	.search-submit{
		border: 0px;
		background: transparent;
		position: absolute;
		right: 10px;
		top: 29%;
	}
}