$white: #fff;
$primary-color:#e7272d;
// $primary-color: rgb(72, 105, 232);
$secondary-color: #f5f5f5;
$pink-color: rgb(249, 72, 119);
$black:#111;
$black-light: #2b2e3a;
$border-color:#e5e5e5;
$primary-font:'Cardo', serif;
$secondary-font:'Open Sans', sans-serif;
$extra-font:'Cookie', cursive;
